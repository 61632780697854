import { Component, Input } from '@angular/core';

export interface UserDataCell {
  avatar?: Component;
  fullName: string;
  email?: string;
  icon?: string;
}

export interface GeneralDataCell {
  icon?: string;
  mainText: string | number;
  subText?: string | number;
  badgeCount?: number;
  mainFontStyle?: 'normal' | 'bold';
}

export interface StatusDataCell {
  statusDisplay: string;
  statusKid: string;
  statusType: 'courseRegistration' | 'courseCatalog' | 'active';
  forceUppercase?: boolean;
}

export interface SpecialDataCell {
  specialType: 'checkbox' | 'icon' | 'number' | 'validation',
  icon?: string;
  actionName: string;
}

export interface HyperlinkCell {
  url: string;
}

export type TableCellTypes = UserDataCell | GeneralDataCell | StatusDataCell | SpecialDataCell | HyperlinkCell;

@Component({
  selector: 'app-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: [ './table-cell.component.scss' ],
})
export class TableCellComponent {
  @Input() cell: TableCellTypes;

  cellIsUserInfo(cell: unknown): cell is UserDataCell {
    return !!(cell as UserDataCell).fullName;
  }

  cellIsGeneralInfo(cell: unknown): cell is GeneralDataCell {
    return !!(cell as GeneralDataCell).mainText;
  }

  cellIsStatusInfo(cell: unknown): cell is StatusDataCell {
    return !!(cell as StatusDataCell).statusDisplay;
  }

  cellIsSpecialInfo(cell: unknown): cell is SpecialDataCell {
    return !!(cell as SpecialDataCell).specialType;
  }

  cellIsHyperlink(cell: unknown): cell is HyperlinkCell {
    return !!(cell as HyperlinkCell).url;
  }

}
