import {
  Component, Input, Output, EventEmitter, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewTableData } from 'src/app/components/new-table/new-table.component';
import { GeneralDataCell } from 'src/app/components/new-table/table-cell/table-cell.component';
import { CoursesService } from 'src/app/services/courses.service';
import { DocumentExportCoursesFilterComponent } from './courses-filter/courses-filter.component';

@Component({
  selector: 'app-select-courses',
  templateUrl: './select-courses.component.html',
  styleUrls: [ './select-courses.component.scss' ],
})
export class SelectCoursesComponent implements OnInit, OnDestroy {
  @Input() isHeader? = false;
  @Output() changeStepEvent = new EventEmitter();
  @Output() setSelectedCoursesEvent = new EventEmitter();
  selectedCourses = [];
  isLoading = false;
  displayFilterModal = false;
  filters = {
    showAllFilterValue: true,
    showAllIsUndetermined: false,
    showSpecialRequestsFilterValue: true,
    showUnchangedFilterValue: true,
    startDate: '',
    endDate: '',
    selectedRequirements: [],
    selectedCategories: [],
    selectedTags: [],
  }

  tableData: NewTableData = {
    columnTitles: [
      {
        name: 'Course',
        sortable: true,
        sortByProp: 'courseName',
        selectableSummary: true,
      },
      {
        name: 'Date/Time',
        sortable: true,
        isDefaultSort: true,
        sortDirection: 'ASC',
        sortByProp: 'dateOffered',
      },
      {
        name: 'Exported/Taken',
        sortable: true,
        sortByProp: 'takenCount',
      },
    ],
    selectable: true,
    data: [],
    noDataFoundMessage: 'No courses found',
    rowStyling: 'default',
    title: 'Courses',
    burgerContent: [
      {
        content: 'View Course Details',
        eventName: 'view-details',
      },
      {
        content: 'View Course Validation',
        eventName: 'view-validation',
      },
    ],
    hasBurgerMenu: false,
    meta: {
      totalPages: 0,
      totalItems: 0,
      itemsPerPage: 0,
      curPage: 0,
      usePagination: false,
      links: {
        first: '',
        last: '',
        next: '',
        prev: '',
        self: '',
      },
    },
  }
  searchText = '';
  filterData = {};
  sortColumn = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortByProp || '';
  sortDirection = this.tableData.columnTitles.find(column => column.isDefaultSort)?.sortDirection || 'ASC';
  offset = 0;
  itemsPerPage = 25;


  courses: any[] = [];
  formattedCourses: NewTableData['data']= [];
  coursesData$ = this.courseService.courseData$;
  coursesSubscription = this.coursesData$.subscribe((data) => {
    this.isLoading = false;
    if (!data) {
      return;
    }

    const { pagination, links } = data;

    const paginationInfo: NewTableData['meta'] = {
      totalPages: pagination.totalPages,
      totalItems: pagination.totalItems,
      itemsPerPage: pagination.totalPerPage,
      curPage: pagination.curPage,
      usePagination: false,
      links,
    }

    // this.tableData.meta = paginationInfo;

    this.courses = data.rows;
    this.formatCourses(this.courses);
    this.tableData.data = this.formattedCourses;
  });

  constructor(public dialog: MatDialog, private courseService: CoursesService) {}

  ngOnInit() {
    this.searchForCourses();
  }

  searchForCourses() {
    this.isLoading = true;
    this.courseService.getCoursesForExport({
      limit: this.itemsPerPage,
      offset: this.offset,
      filterString: this.searchText,
      sortColumn: this.sortColumn,
      sortDirection: this.sortDirection,
      filterData: this.filterData,
      includedAssociations: 'Requirements,PendingRegistrations',
    })
  }

  getAppliedFilterCount() {
    if (true) {
      return ''
    }

    return ' (0)';
  }

  formatCourses(courses: any[]): typeof this.formattedCourses {
    this.formattedCourses = courses.map(course => {
      const courseData = {
        courseName: course.courseName,
        courseItemId: course.courseItemId,

        dateOffered: new Date(course.dateOffered),
        sessionStartDate: new Date(course.CourseItem.sessionStartDate),
        sessionEndDate: new Date(course.CourseItem.sessionEndDate),

        status: course.status,
        exportedCount: course.stateDocExportedCount,
        takenCount: course.takenCount,
      }

      const courseCell: GeneralDataCell = {
        mainText: courseData.courseName,
        subText: courseData.courseItemId.toString(),
      }

      const dateCell: GeneralDataCell = {
        mainText: courseData.dateOffered.toLocaleDateString(),
        subText: `${courseData.sessionStartDate.toLocaleTimeString('en',
          {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })} - ${courseData.sessionEndDate.toLocaleTimeString('en',
          {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })}`,
      };

      const countCell: GeneralDataCell = { mainText: `${courseData.exportedCount} / ${courseData.takenCount}`, mainFontStyle: 'normal' };

      return {
        itemId: course.courseItemId,
        columnData: [
          courseCell, dateCell, countCell,
        ],
      }
    });

    return this.formattedCourses;
  }

  handleSearchChange(value: string) {
    this.searchText = value;
    this.searchForCourses();
  }

  // #region Table Events
  handleBurgerMenuClicked({ eventName, itemId }: { eventName: string; itemId: number; }) { }

  handleRowClicked(itemId: number) {}

  handleSortClicked({ columnName, sortDirection }: { columnName: string, sortDirection: 'ASC' | 'DESC' }) {
    this.sortColumn = columnName;
    this.sortDirection = sortDirection;
    this.searchForCourses();
  }

  handleSelectedRowsChanged = (rows) => {
    this.selectedCourses = [];
    if (rows.length) {
      rows.forEach((row) => {
        const course = this.courses.find(course => course.courseItemId === row.itemId);
        this.selectedCourses.push(course)
      })
    }
    this.setSelectedCoursesEvent.emit(this.selectedCourses);
  }

  handlePaginationEvent(eventData: any) {
    console.log('handlePaginationEvent -> eventData', eventData)
  }

  handleItemsPerPageChange(itemsPerPage: number) {
    this.offset = 0;
    this.itemsPerPage = itemsPerPage;
    this.searchForCourses();
  }

  handlePageChanged(page: number) {
    const offset = (page - 1) * this.tableData.meta.itemsPerPage;
    this.offset = offset;
    this.searchForCourses();
  }
  // #endregion Table Events

  openExportSettingsModal = () => {
    const dialog = this.dialog.open(DocumentExportCoursesFilterComponent, { disableClose: false, data: this.filters })

    dialog.afterClosed().subscribe((result) => {
      this.filters = result;
      this.filterData = {
        showSpecialRequests: result.showSpecialRequestsFilterValue ? 1 : 0,
        showUnchanged: result.showUnchangedFilterValue ? 1 : 0,
        dateRangeStart: result.startDate ? result.startDate : '',
        dateRangeEnd: result.endDate ? result.endDate : '',
        requirementIds: result.selectedRequirements.map(requirement => requirement.requirementId),
        categoryIds: result.selectedCategories.map(category => category.categoryId),
        tagIds: result.selectedTags.map(tag => tag.id),
      }
      this.searchForCourses()
    });
  }

  closeFilterModal() {
    this.displayFilterModal = false;
  }

  isShowAllFilterValue() {
    return this.filters.showAllFilterValue;
  }

  nextStep = () => {
    this.changeStepEvent.emit(1);
  }

  ngOnDestroy() {
    this.coursesSubscription.unsubscribe();
  }
}
