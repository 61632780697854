<app-new-table
  [isLoading]="isLoading"
  [tableData]="tableData"
  (rowClickedEvent)="handleRowClicked($event)"
  (burgerMenuClickedEvent)="handleBurgerMenuClicked($event)"
  (sortClickedEvent)="handleSortClicked($event)"
  (noDataButtonClickedEvent)="handleNoDataButtonClicked()"
  (itemsPerPageChange)="onItemsPerPageChange($event)"
  (pageChanged)="onPageChanged($event)"
></app-new-table>
