type KalpaBoolean = 0 | 1 | boolean;

interface DocumentItem {
  fileName: string;
  displayName: string;
  mimeType: string;
  buffer: {
    type: string;
    data: number[];
  };
  description: string;
}

interface CourseItemParams {
  courseId?: number;
  preRecordedOption?: 'ASYNC' | 'ANYTIME' | '';

  regStartDate?: Date;
  regStartDateFormatted?: string;
  regEndDate?: Date;
  regEndDateFormatted?: string;
  dateOffered?: Date;
  dateOfferedFormatted?: string;
  sessionStartDate?: Date | string;
  sessionStartDateFormatted?: string | Date;
  sessionEndDate?: Date | string;
  sessionEndDateFormatted?: string | Date;

  location?: string;
  roomNumber?: string;
  instructor?: string;
  instructorId?: number;
  courseSize?: number;
  registeredCount?: number;

  hyperlink?: string;

  allowWaitListFlag?: KalpaBoolean;
  virtualFlag?: KalpaBoolean;
  surveyFlag?: KalpaBoolean
  // pin?: string;
  buildingId?: number;

  documentsArray?: DocumentItem[];
}

const propsMap: { [prop: string]: string[] } =
{
  courseName: [ 'Course', 'courseName' ],
  courseActiveFlag: [ 'Course', 'activeFlag' ],
  courseDescription: [ 'Course', 'courseDescription' ],
  courseCost: [ 'Course', 'courseCost' ],
  trackingValue: [ 'Course', 'trackingValue' ],

  outsideCourseFlag: [ 'Course', 'outsideCourseFlag' ],
  certificateFlag: [ 'Course', 'certificateFlag' ],
  excludeStateReportFlag: [ 'Course', 'excludeStateReportFlag' ],
  addToCatalogFlag: [ 'Course', 'addToCatalogFlag' ],

  courseType: [ 'Course', 'CourseType' ],
  courseTypeId: [
    'Course', 'CourseType', 'id',
  ],

  // stateRequirement: [],
  stateRequirementId: [ 'Course', 'stateRequirement' ],

  // survey: [ ],
  surveyId: [ 'Course', 'surveyId' ],
  surveyFlag: [ 'Course', 'surveyFlag' ],

  approval: [
    'Course', 'Approval', 'approvalName',
  ],
  approvalKid: [ 'Course', 'approvalKid' ],
  approvalId: [ 'Course', 'approvalId' ],

  validation: [
    'Course', 'ValidationType', 'description',
  ],
  validationKid: [
    'Course', 'ValidationType', 'kalpaIdentifier',
  ],
  validationId: [ 'Course', 'validationId' ],
}

const itemPropsMap: { [prop: string]: string[] } = {
  courseId: [ 'courseId' ],
  courseItemId: [ 'id' ],
  courseItemActiveFlag: [ 'activeFlag' ],
  preRecordedOption: [ 'preRecordedOption' ],
  regStartDate: [ 'regStartDate' ],
  regEndDate: [ 'regEndDate' ],
  dateOffered: [ 'dateOffered' ],
  sessionStartDate: [ 'sessionStartDate' ],
  sessionEndDate: [ 'sessionEndDate' ],

  location: [ 'location' ],
  roomNumber: [ 'roomNumber' ],
  instructor: [ 'instructor' ],
  instructorId: [ 'instructorId' ],
  courseSize: [ 'courseSize' ],
  registeredCount: [ 'gcRegisteredCount' ],

  hyperlink: [ 'hyperlink' ],

  allowWaitListFlag: [ 'allowWaitListFlag' ],
  virtualFlag: [ 'virtualFlag' ],
  buildingId: [ 'buildingId' ],
}

const arrayMap: { [prop: string]: string[] } = {
  tagsList: [ 'Course', 'Tags' ],
  categoryList: [ 'Course', 'Categories' ],
  requirementsList: [ 'Course', 'Requirements' ],
};

export const formatDate = (date: Date, forDisplay = false) => {
  const formattedDate = date instanceof Date ? date : new Date(date);

  const year = formattedDate.getFullYear();

  let month = (1 + formattedDate.getMonth()).toString();

  let day = formattedDate.getDate().toString();

  if (month.length === 1) {
    month = `0${month}`;
  }
  if (day.length === 1) {
    day = `0${day}`;
  }

  const forDisplayFormat = `${month}/${day}/${year}`;
  const stringFormat = `${year}-${month}-${day}`

  return forDisplay ? forDisplayFormat : stringFormat;
}

export const formatTime = (time: Date | string, returnDate = false) => {
  time = time instanceof Date ? time : new Date(time);

  const date = new Date(time);


  if (returnDate) {
    return date;
  }

  return date.toLocaleTimeString('en-US', {
    hour: '2-digit', minute: '2-digit', hourCycle: 'h12',
  }).toLowerCase();

}

export default class Course implements CourseItemParams {
  edited = false;
  // #region Properties
  public originalCourse?: Course;
  public courseActiveFlag?: KalpaBoolean = true;
  public courseName?: string;
  public courseDescription?: string;
  public courseCost?: string;
  public trackingValue?: string;

  public specialRequestFlag?: KalpaBoolean = false;
  public outsideCourseFlag?: KalpaBoolean = false;
  public certificateFlag?: KalpaBoolean = false;
  public excludeStateReportFlag?: KalpaBoolean = false;
  public addToCatalogFlag?: KalpaBoolean = true;


  public courseType?: any;
  public courseTypeId?: number;

  // public stateRequirement: string;
  public stateRequirementId?: number;

  public survey?: string;
  public surveyId?: number;
  public surveyFlag?: KalpaBoolean = false;

  public approval?: string;
  public approvalKid?: string;
  public approvalId?: number;

  public validation?: string;
  public validationKid?: string;
  public validationId?: number;

  public tagsList: any[] = [];
  public tagsIdList?: number[];
  public addTagsIdList?: number[] = [];
  public removeTagsIdList?: number[] = [];

  public categoryList: any[] = [];
  public categoryIdList?: number[];
  public addCategoryIdList?: number[] = [];
  public removeCategoryIdList?: number[] = [];

  public requirementsList: any[] = [];
  public requirementsIdList?: number[];
  public addRequirementsIdList?: number[] = [];
  public removeRequirementsIdList?: number[] = [];

  public courseId?: number;
  public courseItemId?: number;
  public courseItemActiveFlag?: KalpaBoolean = true;
  public preRecordedOption?: 'ASYNC' | 'ANYTIME' | '';
  public isAsync?: KalpaBoolean;
  public isAnytime?: KalpaBoolean;
  public regStartDate?: Date;
  public regStartDateFormatted?: string;
  public regEndDate?: Date;
  public regEndDateFormatted?: string;
  public dateOffered?: Date;
  public dateOfferedFormatted?: string;
  public sessionStartDate?: Date;
  public sessionStartDateFormatted?: string;
  public sessionEndDate?: Date;
  public sessionEndDateFormatted?: string;

  public location?: string;
  public roomNumber?: string;
  public instructor?: string;
  public instructorId?: number;
  public courseSize: number;
  public registeredCount = 0;

  public hyperlink?: string;

  public cancelFlag?: KalpaBoolean;
  public allowWaitListFlag?: KalpaBoolean;
  public virtualFlag?: KalpaBoolean;
  // public pin?: string;
  public buildingId?: number;

  // #endregion Properties

  constructor(courseInfo?: any, originalCourseInfo?: Course) {
    if (!courseInfo) {
      return;
    }
    this.mapPropsToCourse(courseInfo);
    this.formatDates();
    this.formatBooleans();
    this.formatIdLists();

    this.isAsync = this.preRecordedOption === 'ASYNC';
    this.isAnytime = this.preRecordedOption === 'ANYTIME';

    if (originalCourseInfo) {
      this.originalCourse = originalCourseInfo;
    }
  }


  setProperty = (property: keyof Course, value: any) => {
    // @ts-ignore
    this.edited = true;

    if (property === 'dateOffered') {
      this[property] = this.formateDateWithTimeOffset(value) as Date;
      return;
    }

    // @ts-ignore
    this[property] = value;
  }

  addToArray = (arrayType: 'requirements' | 'categories' | 'tags', item: any) => {
    this.edited = true;

    if (arrayType === 'requirements') {
      this.requirementsList.push(item);
    }
    if (arrayType === 'categories') {
      this.categoryList.push(item);
    }
    if (arrayType === 'tags') {
      this.tagsList.push(item);
    }
  }

  removeFromArray = (arrayType: 'requirements' | 'categories' | 'tags', itemId: number) => {
    this.edited = true;

    if (arrayType === 'requirements') {
      this.requirementsList = this.requirementsList.filter((requirement) => requirement.requirementId != itemId && requirement.id != itemId);
    }
    if (arrayType === 'categories') {
      this.categoryList = this.categoryList.filter((category) => category.id != itemId);
    }
    if (arrayType === 'tags') {
      this.tagsList = this.tagsList.filter((tag) => tag.id != itemId);
    }
  }

  mapPropsToCourse(courseInfo: any) {
    Object.keys({
      ...propsMap, ...itemPropsMap, ...arrayMap,
    }).forEach((key) => {
      const prop = key;
      const propMap = propsMap[prop] || itemPropsMap[prop] || arrayMap[prop];

      const finalValue = propMap.
        reduce((acc, prop) => acc[prop], courseInfo);

      // @ts-ignore
      this[prop] = finalValue;
    });
  }

  formatBooleans() {
    this.courseActiveFlag = this.courseActiveFlag === 1;
    this.specialRequestFlag = this.specialRequestFlag === 1;
    this.outsideCourseFlag = this.outsideCourseFlag === 1;
    this.certificateFlag = this.certificateFlag === 1;
    this.excludeStateReportFlag = this.excludeStateReportFlag === 1;
    this.addToCatalogFlag = this.addToCatalogFlag === 1;
    this.cancelFlag = this.cancelFlag === 1;
    this.allowWaitListFlag = this.allowWaitListFlag === 1;
    this.virtualFlag = this.virtualFlag === 1;
    this.surveyFlag = this.surveyFlag === 1;
  }

  formatDates(addTimeOffset = false) {
    this.regStartDate = this.regStartDate ? new Date(this.regStartDate) : undefined;
    this.regEndDate = this.regEndDate ? new Date(this.regEndDate) : undefined;
    this.dateOffered = this.dateOffered ? new Date(this.dateOffered) : undefined;
    this.sessionStartDate = this.sessionStartDate ? new Date(this.sessionStartDate) : undefined;
    this.sessionEndDate = this.sessionEndDate ? new Date(this.sessionEndDate) : undefined;

    this.regStartDateFormatted = this.regStartDate ? formatDate(this.regStartDate) : '';
    this.regEndDateFormatted = this.regEndDate ? formatDate(this.regEndDate) : '';
    this.dateOfferedFormatted = this.dateOffered ? formatDate(this.dateOffered) : '';
    this.sessionStartDateFormatted = this.sessionStartDate ? formatTime(this.sessionStartDate, false) as string : '';
    this.sessionEndDateFormatted = this.sessionEndDate ? formatTime(this.sessionEndDate, false) as string : '';
  }

  formateDateWithTimeOffset(date: Date | undefined, suppliedOffset?: number) {
    if (!date) {
      return '';
    }

    const newDate = new Date(date);
    const newDateTimeOffset = newDate.getTimezoneOffset() / 60;

    const offset = suppliedOffset !== undefined ? suppliedOffset : newDateTimeOffset;

    newDate.setHours(newDate.getHours() + offset);
    return newDate;
  }

  setToSameDay(date: Date, time: Date | string) {
    const dateYear = date.getFullYear();
    const dateMonth = date.getMonth();
    const dateDay = date.getDate();

    let timeHours = 0;
    let timeMinutes = 0;

    if (typeof time === 'string') {
      timeHours = Number(time.split(':')[0]);
      timeMinutes = Number(time.split(':')[1]);
    } else {
      timeHours = time.getHours();
      timeMinutes = time.getMinutes();
    }

    // use the date's year, month, and day, but set the hours and minutes to the time's hours and minutes
    const finalTime = new Date(
      dateYear, dateMonth, dateDay, timeHours, timeMinutes,
    );

    return finalTime;
  }

  formatDatesWithTimes() {
    const isAnytime = this.isAnytime;
    const isAsync = this.isAsync;

    if (isAsync) {
      this.sessionStartDate = undefined;
      this.sessionEndDate = undefined;
      this.dateOffered = undefined;
    } else if (isAnytime) {
      this.sessionStartDate = undefined;
      this.sessionEndDate = undefined;
    } else {
      this.sessionStartDate = this.setToSameDay(this.dateOffered as Date, this.sessionStartDate as Date);
      this.sessionEndDate = this.setToSameDay(this.dateOffered as Date, this.sessionEndDate as Date);
    }
  }

  formatIdLists() {
    this.requirementsList = [ ...this.requirementsList ];
    this.tagsList = [ ...this.tagsList ];
    this.categoryList = [ ...this.categoryList ];
  }

  setBasicProps(props: Course) {
    const courseProps = {
      courseName: props?.courseName,
      courseDescription: props?.courseDescription,
      courseCost: props?.courseCost || null,
      trackingValue: props?.trackingValue,

      outsideCourseFlag: props?.outsideCourseFlag,
      certificateFlag: props?.certificateFlag,
      surveyFlag: props?.surveyFlag,
      excludeStateReportFlag: props?.excludeStateReportFlag,
      addToCatalogFlag: props?.addToCatalogFlag,

      // stateRequirementId: props?.stateRequirementId,
      surveyId: props?.surveyId,
      courseTypeId: props?.courseTypeId,
      approvalId: props?.approvalId,
      validationId: props?.validationId,
    }
    const courseItemProps: CourseItemParams = {
      preRecordedOption: props?.preRecordedOption,
      regStartDate: props?.regStartDate,
      regEndDate: props?.regEndDate,
      dateOffered: props?.dateOffered,
      sessionStartDate: props?.sessionStartDate,
      sessionEndDate: props?.sessionEndDate,
      location: props?.location,
      roomNumber: props?.roomNumber,
      instructor: props?.instructor,
      instructorId: props?.instructorId,
      courseSize: props?.courseSize,
      registeredCount: props?.registeredCount,
      hyperlink: props?.hyperlink,
      allowWaitListFlag: props?.allowWaitListFlag,
      virtualFlag: props?.virtualFlag,
      buildingId: props?.buildingId,
    }

    const arrayProps = {
      requirementIdList: props.requirementsList ? props?.requirementsList?.map((requirement) => requirement.requirementId || requirement.id) : [],
      tagIdList: props.tagsList ? props?.tagsList?.map((tag) => tag.id) : [],
      categoryIdList: props.categoryList ? props?.categoryList?.map((category) => category.id) : [],
    }

    return {
      courseProps, courseItemProps, arrayProps,
    };
  }

  buildCreateArrays(updatedCourseInfo: Record<string, any>) {
    const updatedRequirementsArray: number[] = updatedCourseInfo['requirementIdList'] || [];
    const updatedTagsArray: number[] = updatedCourseInfo['tagIdList'] || [];
    const updatedCategoriesArray: number[] = updatedCourseInfo['categoryIdList'] || [];

    const createArrays: {
      requirementsIdList?: number[],
      tagsIdList?: number[],
      categoryIdList?: number[],
    } = {}

    if (updatedRequirementsArray.length > 0) {
      createArrays['requirementsIdList'] = updatedRequirementsArray.map(r => Number(r));
    }

    if (updatedTagsArray.length > 0) {
      createArrays['tagsIdList'] = updatedTagsArray.map(t => Number(t));
    }

    if (updatedCategoriesArray.length > 0) {
      createArrays['categoryIdList'] = updatedCategoriesArray.map(c => Number(c));
    }

    return createArrays;
  }

  buildUpdateArrays(originalCourseInfo: Record<string, any>, updatedCourseInfo: Record<string, any>) {
    const originalRequirementsArray: number[] = originalCourseInfo['requirementIdList'];
    const updatedRequirementsArray: number[] = updatedCourseInfo['requirementIdList'];

    const originalTagsArray: number[] = originalCourseInfo['tagIdList'];
    const updatedTagsArray: number[] = updatedCourseInfo['tagIdList'];

    const originalCategoriesArray: number[] = originalCourseInfo['categoryIdList'];
    const updatedCategoriesArray: number[] = updatedCourseInfo['categoryIdList'];

    const addRequirementsIdList = updatedRequirementsArray?.filter((requirement) => !originalRequirementsArray.includes(requirement));
    const removeRequirementsIdList = originalRequirementsArray?.filter((requirement) => !updatedRequirementsArray.includes(requirement));

    const addCategoryIdList = updatedCategoriesArray?.filter((category) => !originalCategoriesArray.includes(category));
    const removeCategoryIdList = originalCategoriesArray?.filter((category) => !updatedCategoriesArray.includes(category));

    const addTagsIdList = updatedTagsArray?.filter((tag) => !originalTagsArray.includes(tag));
    const removeTagsIdList = originalTagsArray?.filter((tag) => !updatedTagsArray.includes(tag));

    const arrayProps: {
      addRequirementsIdList?: number[],
      removeRequirementsIdList?: number[],
      addCategoryIdList?: number[],
      removeCategoryIdList?: number[],
      addTagsIdList?: number[],
      removeTagsIdList?: number[],
    } = {}

    if (addRequirementsIdList?.length > 0) {
      arrayProps['addRequirementsIdList'] = addRequirementsIdList.map(r => Number(r));
    }
    if (removeRequirementsIdList?.length > 0) {
      arrayProps['removeRequirementsIdList'] = removeRequirementsIdList.map(r => Number(r));
    }

    if (addCategoryIdList?.length > 0) {
      arrayProps['addCategoryIdList'] = addCategoryIdList.map(c => Number(c));
    }
    if (removeCategoryIdList?.length > 0) {
      arrayProps['removeCategoryIdList'] = removeCategoryIdList.map(c => Number(c));
    }

    if (addTagsIdList?.length > 0) {
      arrayProps['addTagsIdList'] = addTagsIdList.map(t => Number(t));
    }
    if (removeTagsIdList?.length > 0) {
      arrayProps['removeTagsIdList'] = removeTagsIdList.map(t => Number(t));
    }


    return arrayProps;
  }

  filterProps(updatedProps: Record<string, any>, originalCourseInfo: Record<string, any>) {
    const reducedObj = Object.keys(updatedProps).reduce((acc, key) => {
      const originalValue = originalCourseInfo[key];
      const updatedValue = updatedProps[key];

      if (updatedValue === undefined) {
        return acc;
      }

      if (originalValue === updatedValue) {
        return acc;
      }

      if (!originalValue && !updatedValue) {
        return acc;
      }

      const dateProps = [
        'regStartDate', 'regEndDate', 'dateOffered',
      ];
      const timeProps = [ 'sessionStartDate', 'sessionEndDate' ];
      if ([ ...dateProps, ...timeProps ].includes(key)) {

        let origDateValue;
        let updatedDateValue;
        let updateValue;

        if (dateProps.includes(key)) {
          origDateValue = formatDate(originalValue);
          updatedDateValue = formatDate(updatedValue);
          updateValue = updatedDateValue;

        } else if (timeProps.includes(key)) {
          origDateValue = formatTime(originalValue, true);
          updatedDateValue = formatTime(updatedValue, true);
          updateValue = updatedDateValue;
        }


        const areEqual = origDateValue === updatedDateValue;


        if (!areEqual) {
          return { ...acc, [key]: updateValue };
        }
        return acc
      }

      if (typeof updatedValue === 'boolean') {
        return { ...acc, [key]: updatedValue ? 1 : 0 };
      }

      if (Number(updatedValue)) {
        return { ...acc, [key]: Number(updatedValue) };
      }

      return { ...acc, [key]: updatedValue };
    }, {} as Record<string, any>);

    return reducedObj;
  }

  initializeForCreate() {
    this.courseActiveFlag = 1;
    this.specialRequestFlag = 0;
    this.outsideCourseFlag = 0;
    this.certificateFlag = 0;
    this.excludeStateReportFlag = 0;
    this.addToCatalogFlag = 1;
    this.cancelFlag = 0;
    this.allowWaitListFlag = 0;
    this.virtualFlag = 0;
    this.surveyFlag = 0;

    this.courseSize = 0;
    this.registeredCount = 0;

    this.requirementsList = [];
    this.tagsList = [];
    this.categoryList = [];

    return this;
  }

  buildUpdateRequest(originalCourseInfo: Course) {
    this.formatDatesWithTimes();

    const {
      courseProps, courseItemProps, arrayProps,
    } = this.setBasicProps(this);

    this.originalCourse = this.originalCourse || originalCourseInfo;

    const {
      courseProps: originalCourseProps,
      courseItemProps: originalCourseItemProps,
      arrayProps: originalArrayProps,
    } = this.setBasicProps(this.originalCourse);

    const filteredCourseProps = this.filterProps(courseProps, originalCourseProps);
    const filteredCourseItemProps = this.filterProps(courseItemProps, originalCourseItemProps);


    const dateProps = [
      'dateOffered',
      'regStartDate',
      'regEndDate',
    ];

    dateProps.forEach((dateProp) => {
      if (filteredCourseItemProps[dateProp]) {
        filteredCourseItemProps[dateProp] = this.formateDateWithTimeOffset(filteredCourseItemProps[dateProp]) as Date;
      }
    });

    const updateArrays = this.buildUpdateArrays(originalArrayProps, arrayProps);

    const updatedProps: { [prop: string]: any, updatedItemsArr?: any[]} = { ...filteredCourseProps, ...updateArrays };

    if (Object.keys(filteredCourseItemProps).length > 0) {
      filteredCourseItemProps['id'] = Number(this.courseItemId);
      updatedProps['updateItemsArray'] = [ filteredCourseItemProps ];
    }

    return updatedProps;
  }

  buildCreateRequest() {
    this.formatDatesWithTimes();
    const {
      courseProps, courseItemProps, arrayProps,
    } = this.setBasicProps(this);

    const createProps = this.filterProps(courseProps, {});
    const createItemProps = this.filterProps(courseItemProps, {});

    const dateProps = [
      'dateOffered',
      'regStartDate',
      'regEndDate',
    ];

    dateProps.forEach((dateProp) => {
      if (createItemProps[dateProp]) {
        createItemProps[dateProp] = this.formateDateWithTimeOffset(createItemProps[dateProp]) as Date;
      }
    });

    const createArrays = this.buildCreateArrays(arrayProps);

    const updatedProps: { [prop: string]: any, itemsArr?: any[]} = { ...createProps, ...createArrays };

    if (Object.keys(createItemProps).length > 0) {
      updatedProps['itemsArray'] = [ createItemProps ];
    }

    return updatedProps;
  }
}
