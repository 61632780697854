<div class="my-course-requests-container">
  <div class="header-container">
    <h1 class="title">My Course Requests</h1>
    <app-search-filter
      id="search"
      [value]="searchQuery"
      placeholder="Search Course Requests"
      (valueChange)="searchQueryChanged($event)"
    />
    <app-new-button
      id="create-button"
      color="green"
      size="sm"
      customPadding="10px 16px"
      customFontSize="16px"
      leftIcon="add"
      (clickHandler)="toggleCreateModal()"
    >
      Submit Course Request
    </app-new-button>
  </div>
  <div class="table-container">
    <app-new-table
      id="my-course-requests-table"
      [isLoading]="loading"
      [tableData]="newTableData"
      (burgerMenuClickedEvent)="handleMeatballClick($event)"
      (rowClickedEvent)="handleRowClick($event)"
      (sortClickedEvent)="handleSortEvent($event)"
      (pageChanged)="onPageChanged($event)"
      (itemsPerPageChange)="onItemsPerPageChange($event)"
      (noDataButtonClickedEvent)="toggleCreateModal()"
    />
  </div>
</div>
