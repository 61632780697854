import {
  Component, Inject, OnInit, ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { RequirementsService } from 'src/app/services/requirements.service';
import { CategoriesService } from 'src/app/services/categories.service';
import { TagsService } from 'src/app/services/tags.service';

@Component({
  selector: 'app-document-export-courses-filter',
  templateUrl: './courses-filter.component.html',
  styleUrls: [ './courses-filter.component.scss' ],
})
export class DocumentExportCoursesFilterComponent implements OnInit {
  @ViewChild('requirementsinput') requirementsinput: any;
  requirementsDataSubscription = new Subscription();
  requirementsData$: Observable<any>;
  requirements = []
  @ViewChild('categoriesinput') categoriesinput: any;
  categoriesDataSubscription = new Subscription();
  categoriesData$: Observable<any>;
  categories = []
  @ViewChild('tagsinput') tagsinput: any;
  tagsDataSubscription = new Subscription();
  tagsData$: Observable<any>;
  tags = []
  defaultFilters = {
    showAllFilterValue: true,
    showAllIsUndetermined: false,
    showSpecialRequestsFilterValue: true,
    showUnchangedFilterValue: true,
    startDate: '',
    endDate: '',
    selectedRequirements: [],
    selectedCategories: [],
    selectedTags: [],
  }
  filters = { ...this.defaultFilters }

  constructor(
    private requirementsService: RequirementsService,
    private categoriesService: CategoriesService,
    private tagsService: TagsService,
    public dialogRef: MatDialogRef<DocumentExportCoursesFilterComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.getRequirements()
    this.getCategories()
    this.getTags()
    this.filters = structuredClone(this.data)
  }

  getRequirements = () => {
    this.requirementsData$ = this.requirementsService.requirementsData$
    this.requirementsService.getRequirements()
    this.requirementsDataSubscription = this.requirementsData$.subscribe(data => {
      if (!data) return;
      this.requirements = data.rows;
    })
  }

  getCategories = () => {
    this.categoriesData$ = this.categoriesService.categoriesData$
    const params = {
      filterString: '',
      activeFlag: 1 as 0 | 1,
      sortColumn: '',
      sortDirection: 'ASC' as 'ASC' | 'DESC',
      limit: 0,
      offset: 0,
    }
    this.categoriesService.getCategories(params)
    this.categoriesDataSubscription = this.categoriesData$.subscribe(data => {
      if (!data) return;
      this.categories = data.rows;
    })
  }

  getTags = () => {
    this.tagsData$ = this.tagsService.tagsData$
    const params = {
      tagNameFilter: '',
      activeFlag: 1 as 0 | 1,
      sortColumn: '',
      sortDirection: 'ASC' as 'ASC' | 'DESC',
      limit: 0,
      offset: 0,
    }
    this.tagsService.getTags(params)
    this.tagsDataSubscription = this.tagsData$.subscribe(data => {
      if (!data) return;
      this.tags = data.rows;
    })
  }

  updateRequirements = (event: any) => {
    if (event.target.value === '') {
      return
    }
    const selectedValue = this.requirements.find((item: any) => item.requirement === event.target.value);
    this.requirementsinput.nativeElement.value = '';
    const alreadySelected = this.filters.selectedRequirements.some(item => item.requirementId === selectedValue.requirementId)
    if (!alreadySelected) {
      this.filters.selectedRequirements.push(selectedValue)
    }
    this.requirementsinput.nativeElement.value = '';
  }

  updateCategories = (event: any) => {
    if (event.target.value === '') {
      return
    }
    const selectedValue = this.categories.find((item: any) => item.category === event.target.value);
    this.categoriesinput.nativeElement.value = '';
    const alreadySelected = this.filters.selectedCategories.some(item => item.categoryId === selectedValue.categoryId)
    if (!alreadySelected) {
      this.filters.selectedCategories.push(selectedValue)
    }
    this.categoriesinput.nativeElement.value = '';
  }

  updateTags = (event: any) => {
    if (event.target.value === '') {
      return
    }
    const selectedValue = this.tags.find((item: any) => item.tag === event.target.value);
    this.tagsinput.nativeElement.value = '';
    const alreadySelected = this.filters.selectedTags.some(item => item.id === selectedValue.id)
    if (!alreadySelected) {
      this.filters.selectedTags.push(selectedValue)
    }
    this.tagsinput.nativeElement.value = '';
  }

  removeRequirement = (requirement: any) => {
    this.filters.selectedRequirements = this.filters.selectedRequirements.filter((item: any) => item.requirementId !== requirement.requirementId)
  }

  removeCategory = (category: any) => {
    this.filters.selectedCategories = this.filters.selectedCategories.filter((item: any) => item.categoryId !== category.categoryId)
  }

  removeTag = (tag: any) => {
    this.filters.selectedTags = this.filters.selectedTags.filter((item: any) => item.id !== tag.id)
  }

  toggleFilterCheckbox = (filter) => {
    this.filters[filter] = !this.filters[filter];
    // click an individual filter box
    if (filter !== 'showAllFilterValue') {
      if (!this.filters.showSpecialRequestsFilterValue && !this.filters.showUnchangedFilterValue) {
        this.filters.showAllIsUndetermined = false
        this.updateAllChecked()
      } else if (this.filters.showSpecialRequestsFilterValue && this.filters.showUnchangedFilterValue) {
        this.filters.showAllIsUndetermined = false
        this.updateAllChecked()
      } else {
        this.filters.showAllIsUndetermined = true
        this.updateAllChecked()
      }
    }
    // click show all checkbox
    if (filter === 'showAllFilterValue') {
      // handle click show all checkbox: if all are selected, deselect all
      if (this.filters.showSpecialRequestsFilterValue && this.filters.showUnchangedFilterValue) {
        this.filters.showAllIsUndetermined = false
        this.filters.showSpecialRequestsFilterValue = false
        this.filters.showUnchangedFilterValue = false
        this.updateAllChecked()
      } else {
        this.filters.showAllIsUndetermined = false
        this.filters.showSpecialRequestsFilterValue = true
        this.filters.showUnchangedFilterValue = true
        this.updateAllChecked()
      }
    }
  }

  updateAllChecked() {
    setTimeout(() => {
      if (!this.filters.showSpecialRequestsFilterValue && !this.filters.showUnchangedFilterValue) {
        this.filters.showAllFilterValue = false
      }
      if (this.filters.showSpecialRequestsFilterValue && this.filters.showUnchangedFilterValue) {
        this.filters.showAllFilterValue = true
      }
    }, 100)
  }

  resetToDefault = () => {
    this.filters = { ...this.defaultFilters }
  }

  cancelChanges = () => {
    this.dialogRef.close({ ...this.data })
  }
  // This is to save changes while in edit mode
  saveChanges = () => {
    this.dialogRef.close({ ...this.filters })
  }

  closeDialog = () => {
    this.cancelChanges()
  }

}
