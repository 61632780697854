<div class="modal" [ngClass]="{remove: mode === 'remove'}">
  <div class="title-row">
    <div class="title-container">
      <span class="ktitle-small">{{modalTitle}}</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()" fontSet="material-icons-round">close</mat-icon>
  </div>
  <div class="content" *ngIf="mode === 'view'">
    <span class="ktitle-tiny title content-title">
      General Information
    </span>
    <div class="input-field">
      <span class="klabel-small label input-label">Survey Title (required)</span>
      <span class="value">{{survey.name}}</span>
    </div>
    <div class="input-field">
      <span class="klabel-small label input-label">Web URL (required)</span>
      <a [href]="survey.url" target="_blank" class="value">{{survey.url}}</a>
    </div>
    <div class="input-field">
      <span class="klabel-small label input-label">Survey Description (required)</span>
      <span class="value">{{survey.description}}</span>
    </div>
    <span class="ktitle-tiny title content-title top-border">
      Survey Status
    </span>
    <div class="switch-container {{mode}}">
      <label class="switch">
        <input disabled [(ngModel)]="survey.activeFlag" type="checkbox" />
        <span class="slider round {{mode}}"></span>
      </label>
      <span class="slider-label">This survey is {{ getIsSurveyActive() }}</span>
    </div>
    <div class="edit-buttons">
      <app-button id="remove-survey-button" *ngIf="mode === 'view'" [class]="'red'" [text]="'Remove Survey'" [icon]="{materialIcon: 'delete'}" (click)="initRemoveMode()"></app-button>
      <app-button [class]="'white'" [text]="'Cancel'" (click)="closeDialog()"></app-button>
      <app-button [text]="submitButtonText" [icon]="submitButtonIcon" (click)="clickSubmitButton()"></app-button>
    </div>
  </div>
  <div class="content" *ngIf="isEditOrNew()">
    <span class="ktitle-tiny title content-title">
      General Information
    </span>
    <div class="input-field">
      <span class="klabel-small label input-label">Survey Title (required)</span>
      <app-input (updatedValue)="updateSurveyTitle($event)" type="text" class="input" [value]="survey.name" placeholder="Enter a Title"/>
    </div>
    <div class="input-field">
      <span class="klabel-small label input-label">Web URL (required)</span>
      <app-input (updatedValue)="updateSurveyURL($event)" type="text" class="input" [value]="survey.url" placeholder="Enter a URL"/>
    </div>
    <div class="input-field">
      <span class="klabel-small label input-label">Survey Description (required)</span>
      <textarea type="text" class="input" [(ngModel)]="survey.description" placeholder="Start typing something..."></textarea>
    </div>
    <span class="ktitle-tiny title content-title top-border">
      Survey Status
    </span>
    <div class="switch-container {{mode}}">
      <label class="switch">
        <input [disabled]="mode === 'new'" [(ngModel)]="survey.activeFlag" type="checkbox" />
        <span class="slider round {{mode}}"></span>
      </label>
      <span class="slider-label">This survey is {{ getIsSurveyActive() }}</span>
    </div>
    <div class="edit-buttons">
      <app-button id="remove-survey-button" *ngIf="mode === 'view'" [class]="'red'" [text]="'Remove Survey'" [icon]="{materialIcon: 'delete'}" (click)="removeSurvey()"></app-button>
      <app-button [class]="'white'" [text]="'Cancel'" (click)="closeDialog()"></app-button>
      <app-button [text]="submitButtonText" [icon]="submitButtonIcon" (click)="clickSubmitButton()"></app-button>
    </div>
  </div>
  <div *ngIf="mode === 'remove'" class="content">
    <span class="remove-text">This survey is currently assigned to {{survey?.Courses.length}} courses. Removing this survey will result in these courses no longer requiring a survey for completion.</span>
    <span class="remove-text">You cannot undo this action. Click ‘Remove Survey’ to confirm this action.</span>
    <div class="edit-buttons">
      <app-button [text]="'Remove Survey' " [class]="'red'" (click)="removeSurvey()"></app-button>
    </div>
  </div>
</div>
