import {
  Component, Inject, Input, Output, EventEmitter, OnInit, ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { PositionsService } from 'src/app/services/positions.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-export-settings',
  templateUrl: './export-settings.component.html',
  styleUrls: [ './export-settings.component.scss' ],
})
export class ExportSettingsComponent implements OnInit {
  @ViewChild('positioninput') positioninput: any;
  @ViewChild('userinput') userinput: any;
  @Output() saveSettingsEvent = new EventEmitter();
  positionDataSubscription = new Subscription();
  positionsData$: Observable<any>;
  positions = []
  userDataSubscription = new Subscription();
  usersData$: Observable<any>;
  users = []
  sideTabs = [
    {
      name: 'File Output',
      icon: 'file_open',
      description: 'By default, when you export this report, the column headers will be the requirement selections you make in step two. You can create new templates or edit current templates here.',
    }, {
      name: 'Position Exclusions',
      icon: 'work',
      description: 'Adding positions to exclude here will disable any user within the selected position to appear on the exported file.',
    }, {
      name: 'User Exclusions',
      icon: 'person',
      description: 'Adding users to exclude here will disable the selected users from appearing on the exported file.',
    },
  ]

  fileOutputTemplateOptions = [
    {
      value: 'System Default',
      text: 'System Default',
    }, {
      value: 'MOECS Export',
      text: 'MOECS Export',
    },
  ]
  currentFileOutputTemplate = this.fileOutputTemplateOptions[0].value
  selectedTab = this.sideTabs[0]
  defaultSettings = {
    includePreviouslyExportedUserData: false,
    groupByExportDate: false,
    fileOutputTemplate: this.fileOutputTemplateOptions[1].value,
    excludedPositions: [],
    excludedUsers: [],
    excludeUsersWithoutPIC: false,
  }
  settings = { ...this.defaultSettings }

  constructor(
    private usersService: UsersService,
    private positionsService: PositionsService,
    public dialogRef: MatDialogRef<ExportSettingsComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.getPositions()
    this.getUsers()
    this.settings = structuredClone(this.data)
  }

  getPositions = () => {
    this.positionsData$ = this.positionsService.positionsData$
    const params = {
      positionTitleFilter: '',
      activeFlag: 1 as 0 | 1,
      sortColumn: 'positionTitle',
      sortDirection: 'ASC' as 'ASC' | 'DESC',
      limit: 100,
      offset: 0,
    };
    this.positionsService.getPositions(params)
    this.positionDataSubscription = this.positionsData$.subscribe(data => {
      if (!data) return;
      this.positions = data.rows;
    })
  }

  getUsers = () => {
    this.usersData$ = this.usersService.usersData$
    const params = {
      userNameFilter: '',
      activeFlag: 1 as 0 | 1,
      sortColumn: 'lastName',
      sortDirection: 'ASC' as 'ASC' | 'DESC',
      limit: 100,
      offset: 0,
      includedAssociations: '',
    };

    this.usersService.getUsers(params);
    this.userDataSubscription = this.usersData$.subscribe(data => {
      if (!data) return;
      this.users = data.rows;
    })
  }

  selectTab = (tab: any) => {
    this.selectedTab = tab
  }

  setFileOutputTemplateOption = (option) => {
    this.settings.fileOutputTemplate = option
  }

  toggleIncludePreviouslyExportedUserData = () => {
    this.settings.includePreviouslyExportedUserData = !this.settings?.includePreviouslyExportedUserData
  }

  toggleGroupByExportDate = () => {
    this.settings.groupByExportDate = !this.settings.groupByExportDate
  }

  toggleExcludeUsersWithoutPIC = () => {
    this.settings.excludeUsersWithoutPIC = !this.settings.excludeUsersWithoutPIC
  }

  updatePositions = (event: any) => {
    if (event.target.value === '') {
      return
    }
    const selectedValue = this.positions.find((item: any) => item.positionTitle === event.target.value);
    this.positioninput.nativeElement.value = '';
    const alreadySelected = this.settings.excludedPositions.some(item => item.id === selectedValue.id)
    if (!alreadySelected) {
      this.settings.excludedPositions.push(selectedValue)
    }
    this.positioninput.nativeElement.value = '';
  }

  removePosition = (position: any) => {
    this.settings.excludedPositions = [ ...this.settings.excludedPositions.filter((item: any) => item.positionTitle !== position.positionTitle) ]
  }

  updateUsers = (event: any) => {
    if (event.target.value === '') {
      return
    }
    const selectedValue = this.users.find((item: any) => item.fullName === event.target.value);
    this.userinput.nativeElement.value = '';
    const alreadySelected = this.settings.excludedUsers.some(item => item.id === selectedValue.id)
    if (!alreadySelected) {
      this.settings.excludedUsers.push(selectedValue)
    }
    this.userinput.nativeElement.value = '';
  }

  removeUser = (user: any) => {
    this.settings.excludedUsers = [ ...this.settings.excludedUsers.filter((item: any) => item.id !== user.id) ]
  }

  resetToDefault = () => {
    this.settings = { ...this.defaultSettings }
  }

  cancelChanges = () => {
    this.dialogRef.close({ ...this.data })
  }
  // This is to save changes while in edit mode
  saveChanges = () => {
    this.dialogRef.close({ ...this.settings })
  }

}
