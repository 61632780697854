import gsap from 'gsap';
import {
  Component, EventEmitter, Input,
  OnInit,
  Output,
} from '@angular/core';
import { TableCellTypes } from './table-cell/table-cell.component';

// #region Interfaces
interface ColumnHeader {
  name: string;
  icon?: string;

  sortable?: boolean;
  sortByProp?: string; // should be the data property name from the DB
  isDefaultSort?: boolean;
  sortDirection?: 'ASC' | 'DESC' | null;

  selectableSummary?: boolean;

  width?: string;
  align?: 'left' | 'right' | 'center';
}

export interface TableRow {
  itemId: number;
  columnData: TableCellTypes[];
}

export interface NewTableData {
  title: string;
  rowStyling: 'default' | 'dense';
  noDataFoundMessage: string;
  noDataButtonText?: string;

  columnTitles: ColumnHeader[];

  data: TableRow[];

  selectable?: boolean;

  hasBurgerMenu?: boolean;
  hamburgerMenuWidth?: string;
  burgerContent?: {
    content: string;
    eventName: string;
    displayCondition?: (itemId: number) => boolean;
  }[];

  meta?: {
    totalItems: number;
    itemsPerPage: number;
    curPage: number;
    totalPages: number;

    usePagination: boolean;
    links: {
      prev: string;
      next: string;
      first: string;
      last: string;
      self: string;
    }
  }
}

export interface TableBurgerClickEvent {
  eventName: string;
  itemId: number;
}

export interface TableSortEvent {
  columnName: string;
  sortDirection: 'ASC' | 'DESC';
}

export interface TableSpecialActionEvent {
  actionName: string;
  itemId: number;
  value?: unknown;
}

export type TableRowClickEvent = number;
// #endregion Interfaces

@Component({
  selector: 'app-new-table',
  templateUrl: './new-table.component.html',
  styleUrls: [ './new-table.component.scss' ],
})
export class NewTableComponent implements OnInit {
  @Input() tableData: NewTableData;
  @Input() isLoading = false;
  @Output() burgerMenuClickedEvent = new EventEmitter<{ eventName: string; itemId: number; }>();
  @Output() sortClickedEvent = new EventEmitter<{ columnName: string; sortDirection: 'ASC' | 'DESC' }>();
  @Output() specialActionClickedEvent = new EventEmitter<{ actionName: string; itemId: number; value?: unknown }>();
  @Output() rowClickedEvent = new EventEmitter<number>();
  @Output() noDataButtonClickedEvent = new EventEmitter();
  @Output() selectedRowsChangedEvent = new EventEmitter();
  @Output() paginationEvent = new EventEmitter<any>();
  @Output() itemsPerPageChange = new EventEmitter<number>();
  @Output() pageChanged = new EventEmitter<number>();

  initialSortedColumn: ColumnHeader | null;
  showActiveItems = true;

  allSelectedStatus: boolean | 'indeterminate' = false;

  selection: NewTableData['data'] = [];
  panelUnSelected: NewTableData['data'] = [];
  summaryOpen = false;
  summaryPanelOpen = false;
  panelInFocus = false;

  ngOnInit() {
    const hasSort = this.tableData.columnTitles.some(col => col.sortable);

    if (!hasSort) {
      return;
    }

    const initialSorted = this.tableData.columnTitles.find(col => col.isDefaultSort) as ColumnHeader;

    if (initialSorted) {
      this.initialSortedColumn = initialSorted;
    }
  }

  // #region Helper Functions

  // #endregion

  // #region Meatball Menu
  filterBurgerMenuOptions(item: number) {
    const burgerMenuOptions = this.tableData.burgerContent || [];

    const filteredBurgerMenuOptions = burgerMenuOptions.filter(option => {
      if (!option.displayCondition) {
        return true;
      }

      return option.displayCondition(item);
    });

    return filteredBurgerMenuOptions;
  }
  // #endregion Meatball Menu

  // #region Selection
  selectAllDisplayed(event: Event) {
    event.stopPropagation();

    if (!this.allSelectedStatus || this.allSelectedStatus === 'indeterminate') {
      this.selection = this.tableData.data;
      this.allSelectedStatus = true;
    } else {
      this.selection = this.selection.filter(item => !this.tableData.data.includes(item));
      this.allSelectedStatus = false;
    }
    this.selectedRowsChangedEvent.emit(this.selection);
  }

  toggleSelectCell(event: Event, itemId: number) {
    event.stopPropagation();
    const itemIndex = this.selection.findIndex(item => item.itemId === itemId);

    if (itemIndex === -1) {
      this.selection.push(this.tableData.data.find(item => item.itemId === itemId) as TableRow);
    } else {
      this.selection = this.selection.filter(item => item.itemId !== itemId);
    }
    this.selectedRowsChangedEvent.emit(this.selection);
    this.checkAllSelected();
  }

  checkIfSelected(itemId: number) {
    return this.selection.some(item => item.itemId === itemId);
  }

  checkIfPanelSelected(itemId: number) {
    if (!itemId) return;
    const isInPanelUnSelected = this.panelUnSelected.some(item => item.itemId === itemId);

    return !isInPanelUnSelected;
  }

  checkAllSelected() {
    const selectedCount = this.selection.length - (this.panelUnSelected.length || 0);
    if (selectedCount === this.tableData.data.length) {
      this.allSelectedStatus = true;
    } else if (selectedCount === 0) {
      this.allSelectedStatus = false;
    } else {
      this.allSelectedStatus = 'indeterminate';
    }
  }

  toggleAllPanelSelection() {
    if (this.panelUnSelected.length === 0) {
      this.panelUnSelected = [ ...this.selection ];

    } else {
      this.panelUnSelected = [];
    }
    this.checkAllSelected();
  }

  checkAllPanelSelected() {
    if (this.panelUnSelected.length === 0) {
      return true;
    } else if (this.panelUnSelected.length === this.selection.length) {
      return false;
    }
    return 'indeterminate';
  }

  togglePanelCell(event: Event, itemId: number) {
    event.stopPropagation();

    const itemIndex = this.panelUnSelected.findIndex(item => item.itemId === itemId);

    if (itemIndex === -1) {
      this.panelUnSelected.push(this.selection.find(item => item.itemId === itemId) as TableRow);
    } else {
      this.panelUnSelected = this.panelUnSelected.filter(item => item.itemId !== itemId);
    }

    this.checkAllSelected();
  }

  toggleSummaryPanel() {
    if (this.summaryPanelOpen) {
      this.closeSummaryPanel();
    } else {
      this.openSummaryPanel();
    }
  }

  filterSelectionAfterPanelClose() {
    const unselected = this.panelUnSelected;
    const origSelected = this.selection;

    const newSelected = origSelected.filter(item => !unselected.includes(item));

    return newSelected;
  }

  openSummaryPanel() {
    this.summaryPanelOpen = true;
    this.panelUnSelected = [];

    setTimeout(() => {
      const summaryPanel = document.getElementById('summary-panel') as HTMLElement;
      summaryPanel.focus();
    }, 0);
  }
  closeSummaryPanel() {
    this.summaryPanelOpen = false;
    this.summaryOpen = false;
    this.selection = this.filterSelectionAfterPanelClose();
    this.panelUnSelected = [];
    this.checkAllSelected();
  }
  checkIfSummaryClose() {
    if (this.summaryPanelOpen) {
      return;
    }

    this.summaryOpen = false;
  }

  stopBubble(event: Event) {
    event.stopPropagation();
  }
  // #endregion

  // #region Output executors
  rowClicked(event: Event, itemId: number) {
    //@ts-ignore
    const srcElementType = event?.target?.['localName'];

    if (srcElementType && (srcElementType === 'input' || srcElementType === 'button')) {
      return;
    }

    this.rowClickedEvent.emit(itemId);
  }
  burgerMenuClicked(eventName: string, itemId: number) {
    this.burgerMenuClickedEvent.emit({ eventName, itemId });
  }

  sortClicked(column: ColumnHeader) {
    this.tableData.columnTitles.forEach(col => {
      if (!col.sortable) {
        return;
      }

      if (col.sortDirection === undefined) {
        col.sortDirection = null;
      }

      if (col.name !== column.name) {
        col.sortDirection = null;
      }
    });


    const curSortDir = column.sortDirection;

    if (curSortDir === null) {
      column.sortDirection = 'ASC';
    } else if (curSortDir === 'ASC') {
      column.sortDirection = 'DESC';
    } else if (curSortDir === 'DESC' && this.initialSortedColumn) {
      column.sortDirection = null;
      this.initialSortedColumn.sortDirection = 'ASC';
    } else {
      column.sortDirection = 'ASC';
    }

    const sortedColumn = this.tableData.columnTitles.find(col => col.sortDirection !== null) as ColumnHeader;
    this.sortClickedEvent.emit({
      columnName: sortedColumn.sortByProp as string,
      sortDirection: sortedColumn.sortDirection as 'ASC' | 'DESC',
    });

    const table = document.querySelector('.table') as HTMLElement;
    // scroll table to top
    table.scrollIntoView();
  }

  specialActionClicked(
    actionName: string, itemId: number, value?: string,
  ) {
    this.specialActionClickedEvent.emit({
      actionName, itemId, value,
    });
  }

  noDataButtonClicked() {
    this.noDataButtonClickedEvent.emit();
  }

  // #endregion

}
