import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'app-new-button',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ],
})
export class NewButtonComponent {
  @Input() color?: 'blue' | 'green' | 'red' | 'yellow' | 'tonal' | 'ghost' | 'bare' = 'blue';
  @Input() size?: 'sm' | 'md' | 'default' = 'default';
  @Input() elevated? = false;
  @Input() disabled? = false;

  @Input() iconStyle: 'filled' | 'outlined' | 'round' | 'sharp' = 'outlined';
  @Input() leftIcon? = '';
  @Input() rightIcon? = '';

  @Input() customPadding = '';
  @Input() customFontSize = '';

  @Output() clickHandler = new EventEmitter<void>();


  handleClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.clickHandler.emit();
  }
}
