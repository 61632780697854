<div class="modal">
  <div class="title-row">
    <div class="title-container">
      <span class="kbody-huge">Apply Filters</span>
    </div>
    <mat-icon class="close-icon" (click)="closeDialog()" fontSet="material-icons-round">close</mat-icon>
  </div>
  <div class="content">
    <div class="section has-divider">
      <div class="title">
        <h4>Basic Filters</h4>
      </div>
      <div class="option">
        <mat-checkbox
          color="#005965"
          class="checkbox"
          [checked]="filters.showAllFilterValue"
          [indeterminate]="filters.showAllIsUndetermined"
          (change)="toggleFilterCheckbox('showAllFilterValue')"
        />
        <p class="label">Show All</p>
      </div>
      <div class="option">
        <mat-checkbox
          color="#005965"
          class="checkbox"
          [checked]="filters.showSpecialRequestsFilterValue"
          (change)="toggleFilterCheckbox('showSpecialRequestsFilterValue')"
        />
        <p class="label">Show special requests</p>
      </div>
      <div class="option">
        <mat-checkbox
          color="#005965"
          class="checkbox"
          [checked]="filters.showUnchangedFilterValue"
          (change)="toggleFilterCheckbox('showUnchangedFilterValue')"
        />
        <p class="label">Show unchanged since last export</p>
      </div>
    </div>

    <div class="section has-divider">
      <div class="title">
        <h4>Filter by date & time</h4>
      </div>
      <div class="input-label">
        <span>Select Date Range</span>
      </div>
      <mat-form-field class="kinput">
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            placeholder="Start Date .."
            [(ngModel)]="filters.startDate"
          />
          <input
            matEndDate
            placeholder="End Date"
            [(ngModel)]="filters.endDate"
          />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="section">
      <div class="title">
        <h4>Filter by requirements, categories & tags</h4>
      </div>
      <div class="input-label">
        <span>Select Requirements (required)</span>
      </div>
      <div class="input-select-container kinput">
        <input #requirementsinput (change)="updateRequirements($event)" class="requirement-input kinput kbody-medium input-select" type="text" placeholder="Type or select a requirement" list="requirementtargets">
        <datalist id="requirementtargets" class="datalist">
          <option class="kbody-medium" *ngFor="let requirement of requirements">{{ requirement.requirement }}</option>
        </datalist>
      </div>
      <span class="input-helper-text">
        The requirements you select are displayed below.
      </span>
      <div class="chips">
        <app-chip
          *ngFor="let requirement of filters?.selectedRequirements"
          (closeChip)="removeRequirement(requirement)"
          [closable]="true"
          [text]="requirement.requirement"
          [class]="'outlined'"
          [disabled]="false"
          >
        </app-chip>
      </div>
    </div>

    <div class="section">
      <div class="input-label">
        <span>Select Categories</span>
      </div>
      <div class="input-select-container kinput">
        <input #categoriesinput (change)="updateCategories($event)" class="category-input kinput kbody-medium input-select" type="text" placeholder="Type or select a category" list="categorytargets">
        <datalist id="categorytargets" class="datalist">
          <option class="kbody-medium" *ngFor="let category of categories">{{ category.category }}</option>
        </datalist>
      </div>
      <span class="input-helper-text">
        The categories you select are displayed below.
      </span>
      <div class="chips">
        <app-chip
          *ngFor="let category of filters?.selectedCategories"
          (closeChip)="removeCategory(category)"
          [closable]="true"
          [text]="category.category"
          [class]="'outlined'"
          [disabled]="false"
          >
        </app-chip>
      </div>
    </div>

    <div class="section">
      <div class="input-label">
        <span>Select Tags</span>
      </div>
      <div class="input-select-container kinput">
        <input #tagsinput (change)="updateTags($event)" class="tag-input kinput kbody-medium input-select" type="text" placeholder="Type or select a tag" list="tagtargets">
        <datalist id="tagtargets" class="datalist">
          <option class="kbody-medium" *ngFor="let tag of tags">{{ tag.tag }}</option>
        </datalist>
      </div>
      <span class="input-helper-text">
        The tags you select are displayed below.
      </span>
      <div class="chips">
        <app-chip
          *ngFor="let tag of filters?.selectedTags"
          (closeChip)="removeTag(tag)"
          [closable]="true"
          [text]="tag.tag"
          [class]="'outlined'"
          [disabled]="false"
          >
        </app-chip>
      </div>
    </div>

    <div class="edit-buttons">
      <app-button [text]="'Reset to Default'" class="reset-to-default-button" [class]="'grey'" (click)="resetToDefault()"></app-button>
      <app-button [text]="'Cancel'" [class]="'white'" (click)="closeDialog()"></app-button>
      <app-button [text]="'Save Changes'" [icon]="{ materialIcon: 'done' }" (click)="saveChanges()"></app-button>
    </div>
  </div>
</div>
