import {
  Component, Inject, Input, OnInit, ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { SurveysService } from 'src/app/services/surveys.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-add-survey',
  templateUrl: './add-survey.component.html',
  styleUrls: [ './add-survey.component.scss' ],
})
export class AddSurveyComponent implements OnInit {
  modalTitle = 'Add New Survey';
  submitButtonText = 'Add Survey';
  submitButtonIcon = { type: 'success' }
  mode = 'new';
  survey = {
    name: '',
    url: '',
    description: '',
    addedByName: '',
    addedByEmail: '',
    status: '',
    active: true,
    activeFlag: 1,
    Courses: [],
  }
  surveySubscription: Subscription;

  constructor(
    private surveyService: SurveysService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<AddSurveyComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.mode === this.data.mode
    if (this.data.mode === 'edit') {
      this.survey = this.data.survey
      this.initEditMode()
    }
    if (this.data.mode === 'view') {
      this.survey = this.data.survey
      this.initViewMode()
    }
    if (this.data.mode === 'remove') {
      this.survey = this.data.survey
      this.initRemoveMode()
    }
  }

  initEditMode = () => {
    this.mode = 'edit'
    this.modalTitle = 'Edit Survey'
    this.submitButtonText = 'Save Changes'
    this.submitButtonIcon = { type: 'success' }
  }

  initViewMode = () => {
    this.mode = 'view'
    this.modalTitle = 'Survey Details'
    this.submitButtonText = 'Edit Details'
    this.submitButtonIcon = { type: 'edit' }
  }

  initRemoveMode = () => {
    this.mode = 'remove'
    this.modalTitle = 'Remove Survey?'
    this.submitButtonText = 'Remove Survey'
    this.submitButtonIcon = { type: '' }
  }

  closeDialog = () => {
    this.dialogRef.close()
  }

  clickSubmitButton = () => {
    if (this.mode === 'new') {
      this.addSurvey()
    } else if (this.mode === 'view') {
      this.initEditMode()
    } else if (this.mode === 'edit') {
      this.updateSurvey()
    }
  }

  updateSurveyTitle = (event) => {
    this.survey.name = event.target.value
  }

  updateSurveyURL = (event) => {
    this.survey.url = event.target.value
  }

  addSurvey = () => {
    this.surveySubscription = this.surveyService.addSurvey(this.survey).subscribe({
      next: (response: any) => {
        this.toastService.setToast({
          text: 'Survey successfully added', type: 'success', icon: true,
        })
        this.dialog.closeAll();
      },
      error: (error) => {
        console.log('error', error);
        this.toastService.setToast({
          text: 'Oops, something went wrong', type: 'error', icon: true,
        })
        this.dialog.closeAll();
      },
    })
  }

  updateSurvey = () => {
    this.surveySubscription = this.surveyService.updateSurvey(this.survey).subscribe({
      next: (response: any) => {
        this.toastService.setToast({
          text: 'Survey successfully updated', type: 'success', icon: true,
        })
        this.dialog.closeAll();
      },
      error: (error) => {
        console.log('error', error);
        this.toastService.setToast({
          text: 'Oops, something went wrong', type: 'error', icon: true,
        })
        this.dialog.closeAll();
      },
    })
  }

  getIsSurveyActive = () => {
    let text = 'active'
    if (this.survey.activeFlag === 0 || !this.survey.activeFlag) {
      text = 'inactive'
    }
    return text
  }

  isEditOrNew = () => {
    if (this.mode === 'edit' || this.mode === 'new') {
      return true
    }
    return false
  }

  removeSurvey = () => {
    this.surveySubscription = this.surveyService.deleteSurvey(this.survey).subscribe({
      next: (response: any) => {
        this.toastService.setToast({
          text: 'Survey successfully removed', type: 'success', icon: true,
        })
        this.dialog.closeAll();
      },
      error: (error) => {
        console.log('error', error);
        this.toastService.setToast({
          text: 'Oops, something went wrong', type: 'error', icon: true,
        })
        this.dialog.closeAll();
      },
    })
  }

}
