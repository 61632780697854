import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExportSettingsComponent } from './export-settings/export-settings.component';
import { ContentContainerButtonGroup } from 'src/app/components/_design-components/content-container/content-container.component';
import { StepperWizardStep } from 'src/app/components/_design-components/stepper/stepper.component';

@Component({
  selector: 'app-state-documentation-export',
  templateUrl: './state-documentation-export.component.html',
  styleUrls: [ './state-documentation-export.component.scss' ],
})
export class StateDocumentationExportComponent {
  selectedCourses = []
  selectedRequirements = []

  displayExportModal = false;

  titleButtons: ContentContainerButtonGroup[] = [
  // {
  //   display: 'View Help/FAQ',
  //   name: 'help',
  //   leftIcon: 'info',
  //   color: 'tonal',
  //   disabled: false,
  //   size: 'sm',
  //   elevated: false,
  // },
    {
      display: 'Export Settings',
      name: 'export',
      leftIcon: 'settings',
      color: 'blue',
      disabled: false,
      size: 'sm',
      elevated: false,
      onClick: () => this.displayExportModal = true,
    },
  ]
  exportSettings = {
    includePreviouslyExportedUserData: false,
    groupByExportDate: false,
    fileOutputTemplate: 'MOECS Export',
    excludedPositions: [],
    excludedUsers: [],
    excludeUsersWithoutPIC: false,
  }

  wizardSteps: StepperWizardStep[] = [
    {
      displayName: 'Select Courses',
      name: 'select-courses',
      completed: false,
    },
    {
      displayName: 'Select Requirements',
      name: 'select-requirements',
      completed: false,
    },
    {
      displayName: 'Finish & Export',
      name: 'finish-export',
      completed: false,
    },
  ]
  activeStep = this.wizardSteps[0].name;

  constructor(public dialog: MatDialog) {}

  handleButtonClicked(eventName: string) {
    if (eventName === 'export') {
      this.openExportSettingsModal()
    }
  }

  openExportSettingsModal = () => {
    const dialog = this.dialog.open(ExportSettingsComponent, { data: this.exportSettings, disableClose: true })

    dialog.afterClosed().subscribe(result => {
      this.exportSettings = result
    });
  }

  goToStep(step: any) {
    const currentStepIndex = this.wizardSteps.findIndex(step => step.name === this.activeStep)
    if (currentStepIndex > step) {
      this.setStepStatus(this.activeStep, false)
      this.setStepStatus(this.wizardSteps[step].name, false)
      this.activeStep = this.wizardSteps[step].name
    } else {
      this.activeStep = this.wizardSteps[step].name
      this.setStepStatus(this.wizardSteps[step - 1].name, true)
    }
  }

  setStepStatus(name: string, isComplete = true) {
    const step = this.wizardSteps.find(step => step.name === name);
    if (step) {
      step.completed = isComplete;
    }
  }

  setSelectedCourses = (courses: any) => {
    this.selectedCourses = courses
  }

  setSelectedRequirements = (requirements: any) => {
    this.selectedRequirements = requirements
  }

}
