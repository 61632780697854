<div class="input">
  <app-icon class="icon" [iconStyle]="iconStyle">{{ icon }}</app-icon>
  <input
    type="text"
    class="input-field"
    [value]="value"
    (input)="handleValueChange($event)"
    [placeholder]="placeholder"
  />
</div>
