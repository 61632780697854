<button
  class="button"
  [ngClass]="[color, size, elevated ? 'elevated' : '']"
  [disabled]="disabled"
  (click)="handleClick($event)"
  [style]="{ padding: customPadding, fontSize: customFontSize }"
>
  <app-icon *ngIf="leftIcon" class="icon" [iconStyle]="iconStyle">
    {{ leftIcon }}
  </app-icon>
  <!-- <p> -->
  <ng-content></ng-content>
  <!-- </p> -->
  <app-icon *ngIf="rightIcon" class="icon" [iconStyle]="iconStyle">
    {{ rightIcon }}
  </app-icon>
</button>
