<div class="modal">
  <div class="title-row">
    <div class="title-container">
      <mat-icon fontSet="material-icons-round">settings</mat-icon>
      <span class="kbody-huge">Export Settings</span>
    </div>
    <mat-icon class="close-icon" (click)="cancelChanges()" fontSet="material-icons-round">close</mat-icon>
  </div>
  <div class="content">
    <div class="side-tabs">
      <div [ngClass]="{active: selectedTab === tab}" class="side-tab" *ngFor="let tab of sideTabs" (click)="selectTab(tab)">
        <mat-icon class="side-tab-icon" fontSet="material-icons-round">{{tab.icon}}</mat-icon>
        <span>{{tab.name}}</span>
      </div>
    </div>
    <div class="tab-content">
      <h4 class="tab-title">{{selectedTab.name}}</h4>
      <span class="tab-description">{{selectedTab.description}}</span>
      <ng-container *ngIf="selectedTab.name === 'File Output'">
        <app-select
          class="file-output-select"
          [placeholder]="'Select template'"
          [label]="'Select template'"
          [options]="fileOutputTemplateOptions"
          [value]="settings?.fileOutputTemplate"
          (selectedValue)="setFileOutputTemplateOption($event)"
          ></app-select>
          <div class="checkbox-container">
            <input type="checkbox"
              [checked]="settings?.includePreviouslyExportedUserData"
              (change)="toggleIncludePreviouslyExportedUserData()"
            />
            <span>Include previously exported user data</span>
          </div>
          <div class="checkbox-container">
            <input type="checkbox"
              [checked]="settings?.groupByExportDate"
              (change)="toggleGroupByExportDate()"
            />
            <span>Group by export date rather than course date</span>
          </div>
      </ng-container>
      <ng-container *ngIf="selectedTab.name === 'Position Exclusions'">
        <span class="klabel-huge">Positions</span>
        <div class="input-select-container kinput">
          <input #positioninput (change)="updatePositions($event)" class="position-input kinput kbody-medium input-select" type="text" placeholder="Type or select a position" list="positiontargets">
          <datalist id="positiontargets" class="datalist">
            <option class="kbody-medium" *ngFor="let position of positions">{{ position.positionTitle }}</option>
          </datalist>
        </div>
        <div class="chips">
          <app-chip
            *ngFor="let position of settings?.excludedPositions"
            (closeChip)="removePosition(position)"
            [closable]="true"
            [text]="position.positionTitle"
            [class]="'outlined'"
            [disabled]="false"
            >
          </app-chip>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedTab.name === 'User Exclusions'">
        <div class="checkbox-container pic-checkbox">
          <input type="checkbox"
            [checked]="settings?.excludeUsersWithoutPIC"
            (change)="toggleExcludeUsersWithoutPIC()"
          />
          <span>Exclude users without a PIC number</span>
        </div>
        <span class="klabel-huge">Select Users</span>
        <div class="input-select-container kinput">
          <input #userinput (change)="updateUsers($event)" class="position-input kinput kbody-medium input-select" type="text" placeholder="Type or select a user" list="usertargets">
          <datalist id="usertargets" class="datalist">
            <option class="kbody-medium" *ngFor="let user of users">{{ user.fullName }}</option>
          </datalist>
        </div>
        <div class="chips">
          <app-chip
            *ngFor="let user of settings?.excludedUsers"
            (closeChip)="removeUser(user)"
            [closable]="true"
            [text]="user.fullName"
            [class]="'outlined'"
            [disabled]="false"
            >
          </app-chip>
        </div>
      </ng-container>
    </div>
    <div class="edit-buttons">
      <app-button [text]="'Reset to Default'" class="reset-to-default-button" [class]="'grey'" (click)="resetToDefault()"></app-button>
      <app-button [text]="'Cancel'" [class]="'white'" (click)="cancelChanges()"></app-button>
      <app-button [text]="'Save Changes'" [icon]="{ materialIcon: 'done' }" (click)="saveChanges()"></app-button>
    </div>
  </div>
</div>
