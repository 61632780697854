import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [ './sidebar.component.scss' ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  sidebarIsOpen = true;
  coursesDropdownIsOpen = false;

  manageCoursesTab = {
    label: 'Manage Courses',
    link: 'manage-courses/index',
    icon: 'list_alt',
    subMenu: [
      {
        label: 'Course Catalog', link: 'manage-courses/course-catalog', icon: 'list_alt',
      },
      { label: 'Validation', link: 'manage-courses/validation' },
      // { label: 'Calendar', link: '' },
      { label: 'Course Requests', link: 'manage-courses/course-requests' },
      // { label: 'Catalog Suggestions', link: '' },
      {
        label: 'State Doc Export', link: 'manage-courses/documentation-export', requireMI: true,
      },
    ],
  };
  // TODO: update sidebar with working links once available
  menuList: { label: string; link: string; icon: string;  class?: string; subMenu?: { label: string; link?: string | undefined; }[] }[] = [
    // {
    //   label: 'Quick Actions', link: '', class: 'quick action', icon: 'add',
    // },
    // {
    //   label: 'Dashboard', link: 'dashboard', icon: 'dashboard',
    // },
    {
      label: 'My PD Plan', link: 'profile', icon: 'person',
    },
    this.manageCoursesTab,
    {
      label: 'Manage Staff', link: 'manage-staff', icon: 'portrait',
    },
    // {
    //   label: 'Bulletin Board',  link: '', icon: 'campaign',
    // },
    // {
    //   label: 'Documents',  link: '', icon: 'folder_open',
    // },
    {
      label: 'Surveys',  link: 'surveys', icon: 'bar_chart',
    },
    // {
    //   label: 'Reports',  link: '', icon: 'insert_chart_outlined',
    // },
    {
      label: 'System Settings',  link: 'system-settings', icon: 'settings',
    },
    // {
    //   label: 'Get Help',  link: '', icon: 'help_center',
    // },
  ]
  selectedMenuItem: any = this.menuList[0];
  permissions: any;
  permissionsSubscription: any;
  stateCode: string;

  entitySubscription = this.authService.user$.subscribe((user: any) => {
    if (!user.Entity) return;

    this.stateCode = user.Entity.stateCode;
  });

  constructor( private authService: AuthService) { }

  ngOnInit(): void {
    this.getSelectedTab()
    this.permissionsSubscription = this.authService.permissions$.subscribe((permissions: any) => {
      if (Object.keys(permissions).length === 0) {
        this.sidebarIsOpen = false;
        return;
      }
      this.permissions = permissions;
      // if the permission for validating courses is set as false, remove the validation card
      if(permissions?.VALIDATE_ATTENDANCE === 0) {
        this.menuList = this.menuList.map((menuItem) => {
          if(menuItem.label === 'Manage Courses') {
            menuItem.subMenu = menuItem.subMenu?.filter((subMenuItem) => subMenuItem.label !== 'Validation')
          }
          return menuItem;
        })
      }
      if(this.permissions.COURSE_SUGGESTIONS === 0) {
        // here remove the catalog suggestions menu item if the user does not have the permission
        this.menuList = this.menuList.map((item: any) => {
          if(item.subMenu) {
            item.subMenu = item.subMenu.filter((subItem: any) => subItem.label !== 'Catalog Suggestions')
          }
          return item;
        })
      }
      // here we are removing the system settings menu item if the user does not have any of the permissions
      if(!this.permissions.SYS_SETTINGS_DEFAULTS && !this.permissions.SYS_SETTINGS_TYPES && !this.permissions.SYS_SETTINGS_CATEGORIES && !this.permissions.SYS_SETTINGS_REQUIREMENTS && !this.permissions.SYS_SETTINGS_BUILDINGS && !this.permissions.SYS_SETTINGS_TAGS && !this.permissions.SYS_SETTINGS_ROLES && !this.permissions.SYS_SETTINGS_POSITIONS) {
        this.menuList = this.menuList.filter((item: any) => item.label !== 'System Settings')
      }
      if(!this.permissions.USER_ACCOUNTS) {
        this.menuList = this.menuList.filter((item: any) => item.label !== 'Manage Staff')
      }
      if(!this.permissions.MANAGE_SURVEYS) {
        this.menuList = this.menuList.filter((item: any) => item.label !== 'Surveys')
      }
      if(!this.permissions.RUN_DATA_REPORTS) {
        this.menuList = this.menuList.filter((item: any) => item.label !== 'Reports')
      }
      if(!this.permissions.EDIT_BULLETIN_BOARD) {
        this.menuList = this.menuList.filter((item: any) => item.label !== 'Bulletin Board')
      }
      if(!this.permissions.COURSE_REQUESTS) {
        this.menuList = this.menuList.map((item: any) => {
          if(item.subMenu) {
            item.subMenu = item.subMenu.filter((subItem: any) => subItem.label !== 'Course Requests')
          }
          return item;
        })
      }
      if(!this.permissions.EXPORT_STATE_DOC || this.stateCode !== 'MI') {
        this.menuList = this.menuList.map((item: any) => {
          if (item.label === 'Manage Courses') {
            item.subMenu = item.subMenu?.filter((subItem: any) => subItem.label !== 'State Doc Export')
          }

          return item;
        })
      }

      // TODO - refactor for any nav item with subnavs to display the only subnav when only 1 subnav exists
      if (this.manageCoursesTab.subMenu?.length === 1) {
        const indexOfManageCourses = this.menuList.findIndex((item) => item.label === 'Manage Courses');
        const submenuItem = this.manageCoursesTab.subMenu[0];

        if (indexOfManageCourses !== -1 && submenuItem) {
          this.menuList[indexOfManageCourses] = this.manageCoursesTab.subMenu[0] as { label: string; link: string; icon: string; };
        }
      } else {
        this.manageCoursesTab.label = 'Manage Courses';
      }
    });
  }

  getSelectedTab = () => {
    // get the selected tab by referencing the current url
    const path = window.location.pathname;
    this.selectedMenuItem = this.menuList.find((menuItem) => {
      const isTopRef = path.includes(menuItem.link);

      if (isTopRef) {
        return menuItem;
      }

      if (menuItem.subMenu) {
        return menuItem.subMenu.find((subMenuItem) => path.includes(subMenuItem.link || ''));
      }

      return false;
    });
  }


  toggleSidebar = () => {
    this.sidebarIsOpen = !this.sidebarIsOpen
    if(this.sidebarIsOpen && this.coursesDropdownIsOpen){
      this.coursesDropdownIsOpen = false;
    }
  }

  toggleDropdown = (label: any) => {
    if(label === 'courses') {this.coursesDropdownIsOpen = !this.coursesDropdownIsOpen}
  }

  ngOnDestroy() {
    this.permissionsSubscription?.unsubscribe();
  }

}
