import {
  Component, Inject, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { UpdateCourseComponent } from '../../../course-catalog/components/update-course/update-course.component';
import { CourseRequestsService } from 'src/app/services/course-requests.service';
import { ToastService } from 'src/app/services/toast.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';

@Component({
  selector: 'app-course-request-response',
  templateUrl: './course-request-response.component.html',
  styleUrls: [ './course-request-response.component.scss' ],
})
export class CourseRequestResponseComponent implements OnInit {
  course: any;
  approvalType: any;
  denialReason: any = '';

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CourseRequestResponseComponent>,
    private courseRequestsService: CourseRequestsService,
    private toastService: ToastService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) { }

  ngOnInit(): void {
    this.course = this.data.course;
    this.approvalType = this.data.approvalType;
    if (this.approvalType === 'approve') {
      this.dialog.closeAll();
      const editModal = this.dialog.open(UpdateCourseComponent, { data: { ...this.course, isCourseRequestApproval: true } })
      editModal.afterClosed().subscribe((result) => {
        if (result) {
          this.dialogRef.close('approved');
        }
      })
    }
  }

  denyRequest(): void {
    this.loadingSpinnerService.setIsLoading(true);
    this.courseRequestsService.denyCourseRequest(this.course, this.denialReason).subscribe({
      next: (response: any) => {
        this.toastService.setToast({
          text: 'Course Request Denied',
          type: 'success',
          dismissible: false,
        })
        this.dialogRef.close('denied');
        this.loadingSpinnerService.setIsLoading(false);
      },
      error: (error) => {
        console.log('error', error);
        this.toastService.setToast({
          text: 'Error denying course request',
          type: 'error',
          dismissible: false,
        });
        this.loadingSpinnerService.setIsLoading(false);
      },
    })
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
