<div class="surveys">
  <span class="ktitle-huge">Surveys</span>
  <div class="content">
    <div class="header-row" ngProjectAs="[header]">
      <div class="left header-side">
        <app-search-filter
          class="search"
          [value]="searchText"
          (valueChange)="handleSearchChange($event)"
        />
      </div>
      <div class="right header-side">
        <app-new-button
          (clickHandler)="toggleViewInactive()"
          size="sm"
          color="tonal"
          customPadding="12px 16px"
        >
          View {{mode === 'active' ? 'Inactive' : 'Active'}}
        </app-new-button>
        <app-new-button
          (clickHandler)="openNewSurveyModal()"
          rightIcon="add"
          size="sm"
          color="green"
          customPadding="12px 16px"
        >
          Add New Survey
        </app-new-button>
      </div>
    </div>
    <app-new-table
      [tableData]="tableData"
      [isLoading]="isLoading"
      (burgerMenuClickedEvent)="handleBurgerMenuClicked($event)"
      (sortClickedEvent)="handleSortClicked($event)"
    />
  </div>
</div>
