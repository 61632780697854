<div class="header-row" ngProjectAs="[header]">
  <div class="left header-side">
    <h4>Select Courses</h4>
    <app-search-filter
      class="search"
      [value]="searchText"
      (valueChange)="handleSearchChange($event)"
    />
  </div>
  <div class="right header-side">
    <app-new-button
      (clickHandler)="openExportSettingsModal()"
      leftIcon="sort"
      size="sm"
      color="tonal"
      customPadding="12px 16px"
      >{{ "Apply Filters" + getAppliedFilterCount() }}</app-new-button
    >
    <app-new-button
      (clickHandler)="nextStep()"
      rightIcon="arrow_right_alt"
      size="sm"
      color="green"
      customPadding="12px 16px"
      [disabled]="selectedCourses.length === 0"
    >
      Continue to next step
    </app-new-button>
  </div>
</div>

<ng-container>
  <app-new-table
    [tableData]="tableData"
    [isLoading]="isLoading"
    (burgerMenuClickedEvent)="handleBurgerMenuClicked($event)"
    (rowClickedEvent)="handleRowClicked($event)"
    (sortClickedEvent)="handleSortClicked($event)"
    (selectedRowsChangedEvent)="handleSelectedRowsChanged($event)"
  />
</ng-container>
